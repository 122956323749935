export const shuffle = (array: Array<any>) => {
  const clonedArray = [...array]

  let currentIndex = clonedArray.length
  let randomIndex

  while (currentIndex !== 0) {
    randomIndex = Math.floor(Math.random() * currentIndex)
    currentIndex -= 1
    ;[clonedArray[currentIndex], clonedArray[randomIndex]] = [
      clonedArray[randomIndex],
      clonedArray[currentIndex],
    ]
  }

  return clonedArray
}

export default {}
