// i18next-extract-mark-ns-start main-page

import React, { FC, useRef } from 'react'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import HighLight from 'src/components/sections/teaser/Highlight'
import TrusedBrandsLogos from 'src/components/sections/TrustedBy/TrusedBrandsLogos'
import H2 from 'src/components/ui/title/H2'
import Subtitle from 'src/components/ui/Subtitle'
import { useIsLargeScreen } from 'src/helpers/layout'
import { useTrustedByAnimations } from 'src/components/sections/TrustedBy/animations'

interface ITrustedByProps {
  className?: string
}

const TrustedBy: FC<ITrustedByProps> = ({ className }) => {
  const { t } = useTranslation('main-page')

  const subtitleRef = useRef<HTMLDivElement>(null)
  const largeTitleRef = useRef<HTMLDivElement>(null)
  const contentRef = useRef<HTMLDivElement>(null)
  const iconsRef = useRef<HTMLDivElement>(null)

  const isLarge = useIsLargeScreen()

  useTrustedByAnimations(subtitleRef, largeTitleRef, contentRef, iconsRef)

  return (
    <section
      data-name={t('trusted us')}
      id={t('trusted us')}
      className={`${className} container py-10 md:py-20 flex flex-wrap flex-col-reverse lg:flex-row`}
    >
      <div ref={iconsRef} className="w-full lg:w-1/2 opacity-0">
        <TrusedBrandsLogos />
      </div>
      <div className="w-full lg:w-1/2 lg:text-right">
        <Subtitle ref={subtitleRef} className="mb-2 md:mb-6 opacity-0">
          {t('trusted us')}
        </Subtitle>
        <H2
          className="opacity-0"
          ref={largeTitleRef}
          firstPart={t('trusted by')}
          secondPart={t('friends and brands')}
        />
        <HighLight
          ref={contentRef}
          isReversed={isLarge}
          className="w-full opacity-0 font-palanquinDark font-semibold"
        >
          {t('trused by content')}
        </HighLight>
      </div>
    </section>
  )
}

TrustedBy.defaultProps = {
  className: '',
}

export default TrustedBy
