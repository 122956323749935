// i18next-extract-mark-ns-start main-page

import React, { FC, useEffect, useState } from 'react'
import { directusFileUrl } from 'src/helpers/directus'
import { shuffle } from 'src/helpers/array'
import { getRandomIntInclusive } from 'src/helpers/math'
import clientImageIds from 'src/components/sections/TrustedBy/list-of-images'
import { motion, AnimatePresence } from 'framer-motion'
import { useTranslation } from 'gatsby-plugin-react-i18next'

interface ITrusedBrandsLogosProps {
  className?: string
}

const NUMBER_OF_LOGOS_TO_DISPLAY = 6
const NEXT_LOGO_INTERVAL_TIME_IN_S = 2

const animationVariants = {
  enter: {
    zIndex: 1,
    opacity: 0,
    position: 'absolute',
  },
  center: {
    zIndex: 1,
    opacity: 1,
    position: 'relative',
    left: 0,
  },
  exit: {
    zIndex: 0,
    opacity: 0,
    position: 'absolute',
  },
}

const getRandomImagesToDisplay = (): Array<string> => {
  const shuffledImages = shuffle(clientImageIds)

  return shuffledImages.splice(0, NUMBER_OF_LOGOS_TO_DISPLAY)
}

const TrusedBrandsLogos: FC<ITrusedBrandsLogosProps> = ({ className }) => {
  const { t } = useTranslation('main-page')

  const [imagesToDispaly, setImagesToDispaly] = useState(
    getRandomImagesToDisplay()
  )

  const isImageCurrentlyUsed = (imgToCheck: string): boolean =>
    !!imagesToDispaly.find((img) => img === imgToCheck)

  const replaceRandomImageWithAnother = () => {
    const shuffledImages = shuffle(clientImageIds)
    const randomUnusedImage = shuffledImages.find(
      (img) => !isImageCurrentlyUsed(img)
    )

    const imageIndexToReplace = getRandomIntInclusive(
      0,
      NUMBER_OF_LOGOS_TO_DISPLAY - 1
    )

    const imagesWithReplacedOne = Object.assign([], imagesToDispaly, {
      [imageIndexToReplace]: randomUnusedImage,
    })

    setImagesToDispaly(imagesWithReplacedOne)
  }

  useEffect(() => {
    const changeLogoInterval = setInterval(() => {
      replaceRandomImageWithAnother()
    }, NEXT_LOGO_INTERVAL_TIME_IN_S * 1000)

    return () => {
      clearInterval(changeLogoInterval)
    }
  })

  return (
    <div className={`${className} flex flex-wrap justify-center`}>
      {imagesToDispaly.map((imageId, index) => (
        // for animations we need the same key - no metter which image we display
        // eslint-disable-next-line react/no-array-index-key
        <div key={index} className="text-center relative mx-1 md:mx-6">
          <AnimatePresence initial={false}>
            <motion.div
              key={imageId}
              className={`${className} flex flex-wrap items-start relative`}
              variants={animationVariants}
              initial="enter"
              animate="center"
              exit="exit"
              transition={{
                duration: NEXT_LOGO_INTERVAL_TIME_IN_S,
                type: 'tween',
              }}
            >
              <img
                key={imageId}
                className="w-36 md:w-40 block"
                style={{}}
                src={directusFileUrl(imageId)}
                alt={t('Brand logo')}
              />
            </motion.div>
          </AnimatePresence>
        </div>
      ))}
    </div>
  )
}

TrusedBrandsLogos.defaultProps = {
  className: '',
}

export default TrusedBrandsLogos
