const clientImageIds = [
  'e617c6e3-b1d8-4af7-8356-5987a57eb30f.png',
  '5c795af6-31a8-4b60-a471-d470a021187a.png',
  '9006448d-58f7-4f6f-9562-b904ee6586d6.png',
  '63c01a26-a0fd-4fbf-a4cc-d78889e97326.png',
  'a537996d-18b2-4c0d-a2fc-e73b2961c9bb.png',
  'ebd03c9c-7ad0-4f2b-92fa-4048abb4fa3f.png',
  '4066ee7f-4478-4972-b3d1-faf37a0d8dd6.png',
  '2ecc099a-39cf-4e98-8182-cb92a18e9362.png',
  'dc6e5ab6-d9d9-40df-97e5-b6e92c7c3e95.png',
  'dcd04c44-6236-44f2-aa95-7f89875e53a2.png',
  '5e13a539-1d5a-4387-922f-345baef84d16.png',
  '02e995ce-8b3c-4d82-ac04-ecef6935db60.png',
  '1a24e38c-d9ee-4184-ac4f-ee994d50eba9.png',
  '64b8d30c-bd80-43bd-b91c-6c25970769e9.png',
  'da7db80f-b3c6-463b-99ab-a1dc85672f08.png',
  '850ea113-0fdf-44fe-8e5e-5f4eb84a6626.png',
  '08443052-528e-4911-9e22-274d52e41685.png',
  'c135be4a-c323-4ec7-85c1-3d17b80b0610.png',
  'f7e02be3-0740-42c6-afcd-a509eb9851b7.png',
  '5db4a6e4-b989-4122-833c-7af7ae4287d4.png',
  'd2131841-73b3-41cd-8e7d-efd237e050e4.png',
  'e2abd05e-e653-497c-8d64-00313867d773.png',
  '1bec10cf-f001-424c-a9df-6b402b50c260.png',
  'a4feea3f-1c61-484a-873a-6941af28a32f.png',
  'af931722-230b-4062-ab70-5521f22bed28.png',
  'c9da1b22-a03d-44dd-a76f-864dc8d9a23e.png',
  '4a1e69c5-2862-48ab-9b77-0ebc80586ea7.png',
  'a86f3f1d-9939-4984-9b18-250124fcd5b6.png',
  '15d8a43a-f58d-48e6-a6a1-fa64303439bd.png',
  '98aaf468-f6ad-4cd7-8b94-52d86e7a40be.png',
  '313894f4-3873-479a-9108-3f17a8529395.png',
  '3614fc73-f64f-40f8-b168-5e2556d731ba.png',
]

export default clientImageIds
