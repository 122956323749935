import React, { FC, useEffect, useRef, useState } from 'react'
import Subtitle from 'src/components/ui/Subtitle'
import H2 from 'src/components/ui/title/H2'
import { useIsMdScreen } from 'src/helpers/layout'
import { useBasicTeaserAnimations } from 'src/components/sections/teaser/basic-teaser/animations'

export interface IBasicTeaserTranslatedContent {
  firstPartOfTitle: string
  secondPartOfTitle: string
  subtitle: string
  sectionName?: string
  image: {
    url: string
    alt: string
  }
}

export interface IBasicTeaserSectionProps {
  translationContent: IBasicTeaserTranslatedContent
  customTag?: string
  isReversed?: boolean
  className?: string
  isImageSnapped?: boolean
  hideImageOnMobile?: boolean
  children: string | JSX.Element | JSX.Element[]
  mobileFooterChildren?: string | JSX.Element | JSX.Element[]
}

const BasicTeaserSection: FC<IBasicTeaserSectionProps> = ({
  translationContent: {
    firstPartOfTitle,
    secondPartOfTitle,
    subtitle,
    image,
    sectionName,
  },
  isReversed,
  customTag,
  isImageSnapped,
  hideImageOnMobile,
  className,
  children,
  mobileFooterChildren,
}) => {
  const subtitleRef = useRef<HTMLDivElement>(null)
  const largeTitleRef = useRef<HTMLDivElement>(null)
  const contentRef = useRef<HTMLDivElement>(null)
  const footerButtonRef = useRef<HTMLDivElement>(null)
  const imgRef = useRef(null)
  const containerRef = useRef(null)
  const relativePhotoContainerRef = useRef(null)
  const [isImageLoaded, setIsImageLoaded] = useState(false)
  const isMd = useIsMdScreen()

  const isSnappingActive = () => isImageSnapped

  const getPhotoOffset = () => {
    const { clientWidth } = document.body
    const currentContainerWidth = containerRef.current.clientWidth

    return (currentContainerWidth - clientWidth) / 2
  }

  const setPhotoOffset = () => {
    const offset = getPhotoOffset()
    imgRef.current.style[isReversed ? 'left' : 'right'] = `${offset}px`
  }

  const getImageHeightDifference = () => {
    const relativeContainerHeight =
      relativePhotoContainerRef.current.clientHeight
    const imageHeight = imgRef.current.clientHeight

    return imageHeight - relativeContainerHeight
  }

  const setSectionBottomMargin = () => {
    containerRef.current.style.marginBottom = `${Math.max(
      0,
      getImageHeightDifference()
    )}px`
  }

  const resetStyles = () => {
    containerRef.current.style.marginBottom = '0px'
  }

  const setDynamicStyles = () => {
    if (isImageSnapped) {
      setPhotoOffset()
      setSectionBottomMargin()
    } else {
      resetStyles()
    }
  }

  useBasicTeaserAnimations(
    subtitleRef,
    largeTitleRef,
    contentRef,
    imgRef,
    footerButtonRef
  )

  useEffect(() => {
    setDynamicStyles()

    window.addEventListener('resize', setDynamicStyles)

    return () => {
      window.removeEventListener('resize', setDynamicStyles)
    }
  }, [isImageLoaded, isMd])

  const textDirection = isReversed ? 'text-right' : ''

  const ContainerTag = `${customTag}` as keyof JSX.IntrinsicElements

  return (
    <ContainerTag
      data-name={sectionName}
      id={sectionName}
      className={`${className} container py-10 md:py-20`}
    >
      <div ref={containerRef} className="">
        <div className={`${textDirection}`}>
          <Subtitle ref={subtitleRef} className="mb-2 md:mb-6 opacity-0">
            {subtitle}
          </Subtitle>
          <H2
            className="opacity-0"
            ref={largeTitleRef}
            firstPart={firstPartOfTitle}
            secondPart={secondPartOfTitle}
          />
        </div>
        <div
          className={`md:flex ${
            isReversed ? 'flex-row-reverse' : ''
          } ${textDirection}`}
        >
          <div ref={contentRef} className="flex-1 opacity-0 z-10">
            {children}
          </div>
          {!hideImageOnMobile && <div className="h-16 md:h-8" />}
          <div
            ref={relativePhotoContainerRef}
            className="w-full md:w-1/2 relative"
          >
            <div
              ref={imgRef}
              className={`opacity-0 ${
                isSnappingActive() ? 'absolute left-0 right-0' : ''
              } ${hideImageOnMobile ? 'hidden md:block' : ''}`}
            >
              <img
                src={image.url}
                alt={image.alt}
                className="w-full"
                onLoad={() => setIsImageLoaded(true)}
              />
            </div>
          </div>
        </div>
      </div>
      {!!mobileFooterChildren && (
        <div
          ref={footerButtonRef}
          className="opacity-0 md:hidden overflow-hidden"
        >
          {mobileFooterChildren}
        </div>
      )}
    </ContainerTag>
  )
}

BasicTeaserSection.defaultProps = {
  isImageSnapped: true,
  hideImageOnMobile: false,
  className: '',
  mobileFooterChildren: null,
  customTag: 'section',
}

export default BasicTeaserSection
