import React, { FC } from 'react'
import BasicTeaser, {
  IBasicTeaserTranslatedContent,
} from 'src/components/sections/teaser/basic-teaser'
import { Link } from 'gatsby-plugin-react-i18next'
import Button from 'src/components/ui/Button'
import HighLight from 'src/components/sections/teaser/Highlight'

interface ITeaserWithHighlightProps {
  isReversed?: boolean
  isImageSnapped?: boolean
  translationContent: IBasicTeaserTranslatedContent & {
    content: string
    ctaLink: string
    ctaText: string
    sectionName: string
  }
}

const TeaserWithHighlight: FC<ITeaserWithHighlightProps> = ({
  isReversed,
  translationContent,
  isImageSnapped,
}) => (
  <BasicTeaser
    translationContent={translationContent}
    isImageSnapped={isImageSnapped}
    isReversed={isReversed}
  >
    <HighLight
      isReversed={isReversed}
      className="font-palanquinDark font-semibold"
    >
      {translationContent.content}
    </HighLight>
    <div
      className={`mt-10 md:mt-16 text-center ${
        isReversed ? 'md:text-right' : 'md:text-left'
      }`}
    >
      <Link to={translationContent.ctaLink}>
        <Button>{translationContent.ctaText}</Button>
      </Link>
    </div>
  </BasicTeaser>
)

TeaserWithHighlight.defaultProps = {
  isReversed: false,
  isImageSnapped: false,
}

export default TeaserWithHighlight
